import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, Grid, makeStyles, Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import loginImage from '../../assets/images/loginImage.png'; // Make sure to update the path to your image
import AccountDetails from "./AccountDetails.js";

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    backgroundColor: '#3f51b5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    width: '80%',
    maxHeight: '90vh',
    borderRadius: '20px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  formSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imageSection: {
    flex: 1,
    backgroundImage: `url(${loginImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat:"no-repeat",
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  blueButton: {
    color: '#fff',
    height: '40px',
    lineHeight: '48px',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'uppercase',
    backgroundColor: '#3f51b5', // replace with BgColor.myBlue if defined
    padding: '0 20px',
    '&:hover': {
      backgroundColor: '#303f9f', // replace with Color.textBlue if defined
    },
  },
  linkText: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: '150px', // Adjust size as necessary
    marginBottom: theme.spacing(2),
  },
  welcomeText: {
    textAlign: 'center',
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const moveToRegister = () => {
    history.push('/register');
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.formSection}>
          <AccountDetails />
          <div className={classes.linkText}>
            <Typography variant="body2">
              {t("Don't have an account?")}{" "}
              <Link href="#" onClick={moveToRegister}>
                {t('Register')}
              </Link>
            </Typography>
          </div>
        </div>
        <div className={classes.imageSection} />
      </Card>
    </div>
  );
};

export default Login;
