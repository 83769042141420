import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
  Link
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { SuccessAlert } from '../../components';
import Const from '../../helpers/const';
import { logo } from '../../assets/index'; // Make sure to adjust the import if necessary
import forgotPassword from '../../assets/images/forgotPassword.png'; // Make sure to update the path to your image

import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'; // Add import for the email icon

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    backgroundColor: '#3f51b5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    width: '80%',
    maxHeight: '90vh',
    borderRadius: '20px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageSection: {
    flex: 1,
    backgroundImage: `url(${forgotPassword})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  blueButton: {
    color: '#fff',
    backgroundColor: '#3f51b5',
    margin: theme.spacing(3, 0, 2),
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
  linkText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  logo: {
    width: '150px',
    marginBottom: theme.spacing(2),
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleDataChange = (event) => {
    setEmail(event.target.value);
  };

  const checkValidation = () => {
    if (!email) {
      setError('Please enter email');
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!checkValidation()) {
      return;
    }

    axios
      .post(Const.BASE_URL + 'forgotPassword', { email })
      .then((response) => {
        setError('');
        setSuccessMessage('Please check your email.');
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.error || 'Server error.';
        setError(errorMessage);
      });
  };

  const moveToLogin = () => {
    history.push('/login');
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.formSection}>
          <div className={classes.logoContainer}>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Typography variant="h5" className={classes.welcomeText}>
              Forgot Password
            </Typography>
          </div>
          {error && <Alert severity="error">{error}</Alert>}
          <SuccessAlert message={successMessage} />
          {!successMessage ? (
            <>
              <TextField
                type="email"
                label="Enter Email"
                variant="outlined"
                name="email"
                onChange={handleDataChange}
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                className={classes.blueButton}
                fullWidth
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <div className={classes.linkText}>
                <Typography variant="body2">
                  OR
                </Typography>
              </div>
              <Link href="#" style={{ marginTop: '10px' }} onClick={moveToLogin}>
                Login
              </Link>
            </>
          ) : (
            <div className={classes.linkText}>
              <Link href="#" style={{ marginTop: '10px' }} onClick={moveToLogin}>
                Login
              </Link>
            </div>
          )}
        </div>
        <div className={classes.imageSection} />
      </Card>
    </div>
  );
};

export default ForgotPassword;
