const styles = {
    questionHead: {
        // textAlign: "left",
        // "& div": {
        //     textAlign: "left"
        // }
    },
    questionDescription: {
        textAlign: "left",
        "& div": {
            textAlign: "left"
        },
        "& ol, & ul": {
            listStyle: 'disc',
            paddingInlineStart: '40px'
        }
    }
};

export {styles};