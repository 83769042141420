import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { BgColor, Color, Fonts } from '../../theme/index';
import { login, setUserRedirect } from '../../redux/actions/authActions';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { logo, logo_chi } from '../../assets/index';
import { Link } from 'react-router';
import SearchIcon from '@material-ui/icons/Search'; // Add import for the search icon
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'; // Add import for the person icon
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'; // Add import for the email icon
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'; // Add import for the lock icon
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'; // Add import for the school icon
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined'; // Add import for the code icon

import {
  TextField,
  Box,
  Button,
  Card,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { AccountCircle, Lock } from '@material-ui/icons';
import axios from 'axios';
import Const from '../../helpers/const';

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    fontSize: '20px',
    fontFamily: Fonts.Medium,
    opacity: '.8',
  },
  smallestBlueTitle: {
    color: Color.textBlue,
    fontSize: '13px',
    fontFamily: Fonts.Regular,
    textTransform: 'uppercase',
  },
  smallText: {
    color: BgColor.myBlack,
    fontSize: '16px',
    fontFamily: Fonts.Regular,
  },
  textFieldBox: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
    width: '100%',
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#aaa',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3f51b5',
      },
    },
    '& .MuiInputAdornment-root': {
      color: '#aaa',
    },
  },
  overlayWrapper: {
    position: 'fixed',
    top: '0',
    left: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    zIndex: '2000',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  mainHeading2: {
    fontSize: '25px',
    fontFamily: Fonts.Medium,
  },
  shareCodeText: {
    color: Color.myBlack,
    fontSize: '18px',
    fontFamily: Fonts.Medium,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  whiteOutlinedButton: {
    width: '100%',
    height: '40px',
    color: Color.textBlue,
    fontFamily: Fonts.Medium,
    borderRadius: '5px',
    backgroundColor: '#fff',
    border: '2px solid ' + BgColor.myBlue,
    padding: '0 20px',
    margin: '5px 0',
  },
  blueButton: {
    color: '#fff',
    height: '40px',
    width: '100%',
    lineHeight: '48px',
    borderRadius: '5px',
    fontFamily: Fonts.Medium,
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'uppercase',
    backgroundColor: BgColor.myBlue,
    padding: '0 20px',
    marginTop: '20px',
    '&:hover': {
      color: Color.textBlue,
      border: '1px solid' + Color.textBlue,
    },
  },
  forgotPass: {
    marginTop: '14px',
    textAlign: 'right',
    marginTop: '10px',
  },
  forgotButton: {
    '&:hover': {
      backgroundColor: 'whitesmoke',
      padding: '5px',
      borderRadius: '7px',
    },
  },
  container: {
    padding: '30px',
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallGrayTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  bigHeading: {
    marginBottom: '-5px',
  },
}));

const TopPortion = ({ label, profileImg }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <img
        src={t('login') === 'LOGIN' ? logo : logo_chi}
        alt="logo"
        style={{ height: '60px', width: 'auto' }}
      />
      <Box>
        <Typography
          variant="caption"
          color="primary"
          className={classes.smallGrayTitle}
        >
          {t('welcome')}
        </Typography>
      </Box>
    </Box>
  );
};

const MainComponent = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((store) => store.auth);

  const [user_type, setUserType] = useState('teacher');
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
  });
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const token = getQueryVariable('token');

    if (token) {
      axios
        .post(Const.BASE_URL + 'verifyEmail', {
          token: token,
        })
        .then((res) => {
          setSuccessMessage('Email is verified.');
        });
    }

    const state = history.location.state;

    if (state) {
      if (!state.from) {
        setSuccessMessage('Sign up successfully! Please check your inbox and verify Email.');
      }
    }
  }, [history.location.state]);

  const setUserTypeFun = (event) => {
    event.persist();
    setUserType(event.target.value);
  };

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  };

  const handleDataChange = (event) => {
    event.persist();
    setLoginData((loginData) => ({
      ...loginData,
      [event.target.name]: event.target.value,
    }));
  };

  const checkValidation = () => {
    return true;
  };

  const handleSubmit = (event) => {
    event.persist();
    if (!checkValidation()) {
      return false;
    }
    dispatch(
      login({
        ...loginData,
        user_type: user_type === 'teacher' ? 1 : 2,
      })
    ).then((res) => {
      if (res.type === 'success') {
        const redirect = auth.userRedirect;
        dispatch(setUserRedirect(redirect));
        history.push(redirect);
      } else if (res.type === 'error') {
        setError(res.payload.error);
      } else {
        setError('Server Error.');
      }
    });
  };

  return (
    <>
      <TopPortion />
      {successMessage && (
        <Alert
          style={{
            width: '100%',
          }}
          severity="success"
        >
          {successMessage}
        </Alert>
      )}
      {error && (
        <Alert
          style={{
            width: '100%',
          }}
          severity="error"
        >
          {error}
        </Alert>
      )}
      <TextField
        label={t('enter_username')}
        variant="outlined"
        name="username"
        onChange={handleDataChange}
        className={styles.textFieldBox}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
           <PersonOutlineIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label={t('password_label')}
        name="password"
        onChange={handleDataChange}
        type="password"
        variant="outlined"
        className={styles.textFieldBox}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box className={styles.forgotPass}>
        <Link href="/forgot-password" className={styles.forgotButton}>
          {t('forgot_password')}
        </Link>
      </Box>
      <Box style={{ marginTop: '10px' }}>
        <Button
          className={styles.blueButton}
          onClick={handleSubmit}
        >
          {t('login')}
        </Button>
      </Box>
    </>
  );
};

export default MainComponent;
